var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (this.images)?_c('div',{staticClass:"images-gallery"},[(_vm.$vuetify.breakpoint.xs)?_c('did',[(this.images.length == 1 && !this.imgError)?_c('img',{staticClass:"image-gallery",attrs:{"src":this.images[this.startingImage - 1],"max-width":"500","max-height":"300","alt":_vm.title.concat('-', _vm.resourceMeta.id)},on:{"error":function($event){return _vm.onImgError()}}}):_vm._e(),(this.images.length == 0 || this.imgError)?_c('img',{attrs:{"src":require("@/assets/images/image_not_found.jpg"),"alt":"Image not found"}}):_vm._e(),(_vm.images.length > 1)?_c('v-carousel',{attrs:{"show-arrows-on-hover":"","delimiter-icon":"mdi-square","progress":""}},_vm._l((_vm.getImagesInOrder(
          this.images,
          this.startingImage
        )),function(image,i){return _c('v-carousel-item',{key:i,attrs:{"src":image,"eager":""}})}),1):_vm._e()],1):_c('div',{staticClass:"gallery"},[(this.images[this.startingImage - 1] && !this.imgError)?_c('img',{staticClass:"image-gallery",attrs:{"src":this.images[this.startingImage - 1],"max-width":"500","max-height":"300","alt":_vm.title.concat('-', _vm.resourceMeta.id)},on:{"click":function($event){$event.stopPropagation();_vm.galleryDisplayed = true},"error":function($event){return _vm.onImgError()}}}):(this.images.length == 0 || this.imgError)?_c('img',{attrs:{"src":require("@/assets/images/image_not_found.jpg"),"alt":"Image not found"}}):_vm._e(),(this.images.length > 1)?_c('div',{staticClass:"info-text hint-text"},[_vm._v(" Für weitere Fotos bitte Bild anklicken ")]):_vm._e(),(this.images.length >= 1)?_c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.galleryDisplayed),callback:function ($$v) {_vm.galleryDisplayed=$$v},expression:"galleryDisplayed"}},[_c('v-card',[_c('v-card-title',[_c('span',[_vm._v(_vm._s(this.title)+" "),(this.images.length > 1)?_c('span',[_vm._v(" Galerie")]):_vm._e()]),_c('v-spacer'),_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.galleryDisplayed = false}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}],null,false,3238020264)})],1),(_vm.images.length > 1)?_c('v-carousel',{attrs:{"show-arrows-on-hover":"","delimiter-icon":"mdi-square","progress":""}},_vm._l((_vm.getImagesInOrder(
              this.images,
              this.startingImage
            )),function(image,i){return _c('v-carousel-item',{key:i,attrs:{"src":image,"eager":""}})}),1):_c('v-carousel',{attrs:{"show-arrows":false,"hide-delimiter-background":""}},_vm._l((_vm.getImagesInOrder(
              this.images,
              this.startingImage
            )),function(image,i){return _c('v-carousel-item',{key:i,attrs:{"src":image,"eager":""}})}),1)],1)],1):_vm._e()],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }