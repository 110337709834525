const DATE_LOCALE = "de-DE";
const DATE_FORMAT = {
  year: "numeric",
  month: "2-digit",
  day: "2-digit"
};

export function formatDate(str: string | Date) {
  if (str instanceof Date) {
    return str.toLocaleDateString(DATE_LOCALE, DATE_FORMAT);
  } else if (/^[0-9]{4}-/.test(str)) {
    // iso input
    return new Date(str).toLocaleDateString(DATE_LOCALE, DATE_FORMAT);
  } else if (/^[0-9][0-9]\.[0-9][0-9]/.test(str)) {
    // already german date format. remove time values if present:
    return str.substring(0, 10);
  } else {
    console.warn("Invalid date format: " + str);
    return "";
  }
}
