
import Vue from "vue";
import { Column } from "@/components/Table.vue";
import DiagramTableSwitch from "@/components/DiagramTableSwitch.vue";
import RangeSelect, { DataObject } from "./RangeSelect.vue";

export default Vue.extend({
  name: "ExtendedDiagramTableSwitch",
  components: {
    RangeSelect,
    DiagramTableSwitch
  },
  props: {
    title: String,
    data: {
      type: Object as () => DataObject,
      required: true
    },
    columns: {
      type: Array as () => Column[],
      required: true
    },
    advice: String,
    xaxisformatter: {
      type: Function,
      default: null
    },
    yaxisformatter: {
      type: Function,
      default: null
    },
    /** Config for diagram */
    restUrl: String
  },
  data: function() {
    return {
      diagramData: {} as DataObject
    };
  }
});
