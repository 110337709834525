
import { Vue } from "vue-property-decorator";
import Axios from "axios";

export function keysToUpperCase(data: any) {
  return typeof data == "object"
    ? data.map((e: any) =>
        Object.keys(e)
          .map(key => ({ [key.toUpperCase()]: e[key] }))
          .reduce((a: any, b: any) => Object.assign({}, a, b))
      )
    : data;
}

export default Vue.extend({
  name: "Loader",
  props: {
    /*
     * Loader types:
     * https://vuetifyjs.com/en/api/v-skeleton-loader/#type
     */
    loader: {
      type: String,
      required: true
    },
    //** name of the database view */
    view: {
      type: String,
      required: true
    },
    /** array of where conditions */
    where: {
      type: Array as () => string[],
      default: () => []
    },
    /** If set to true a single row is expected as result of db view and instead of an array a single object will be returned as data */
    singleton: {
      type: Boolean,
      default: false
    },
    /** If set to true all keys of returned objects are in lower case instead of caps */
    lowerCaseFieldNames: {
      type: Boolean,
      default: false
    }
  },
  data: () => {
    return {
      loading: true,
      baseUrl: "/data",
      delay: 500,
      data: null,
      error: false
    };
  },
  watch: {
    where: {
      handler: function(v, vOld) {
        if (JSON.stringify(v) != JSON.stringify(vOld)) this.onLoad();
      },
      immediate: true
    }
  },
  methods: {
    onLoad() {
      console.info("loading....");
      if (!this.view || !this.where) return;
      this.load(this.view, this.where)
        .then(data => {
          if (!this.lowerCaseFieldNames && data) {
            data = keysToUpperCase(data);
          }
          if (this.singleton) {
            data = data && data[0];
          }
          this.$emit("loaded", data);
          this.data = data;
        })
        .catch(error => {
          console.log("Loading error: " + error);
          this.error = true;
        });
    },
    startLoading(): number {
      // change loading state with delay
      return setTimeout(() => (this.loading = true), this.delay);
    },
    finishLoading(timeout: number) {
      // clear loading timeout if request was to fast
      clearTimeout(timeout);
      // clear loading state
      this.loading = false;
    },
    async load(view: string, where: string[]) {
      // create request params
      const params = new URLSearchParams();

      const url = this.baseUrl + "/" + view;
      if (where) where.forEach(w => params.append("w", w));

      const loadDelay = this.startLoading();
      // do request
      try {
        const response = await Axios.get(url, {
          params,
          responseType: "json"
        });
        this.finishLoading(loadDelay);
        return response.data;
      } catch (err) {
        this.finishLoading(loadDelay);
        throw err;
      }
    }
  }
});
