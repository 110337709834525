
import { Vue, Component, Prop } from "vue-property-decorator";

@Component({})
export default class Navigation extends Vue {
  @Prop({ required: true })
  links!: any;

  value = {};

  getData(): any {
    return { value: "" };
  }

  getId(): string {
    return this.$route.params.id;
  }
}
