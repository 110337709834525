
import { Vue, Component, Prop } from "vue-property-decorator";
import Loader from "@/components/Loader.vue";

interface Kontakt {
  adressgruppe: string;
  name: string;
  firma: string;
  email: string;
  datentyp: string;
}

@Component({ components: { Loader } })
export default class DownloadExport extends Vue {
  @Prop({ required: true })
  id!: string;
  @Prop({ required: true })
  name!: string;
  @Prop({ required: true })
  where!: string[];
  @Prop({ default: null })
  type!: string;
  @Prop({ default: false })
  disabled!: boolean;
  @Prop({ default: "/messstellen/kontakt" })
  kontaktPath!: string;

  param: any = null;
  from = "";
  to = "";
  kontakt: Kontakt = {
    adressgruppe: "",
    name: "",
    firma: "",
    email: "",
    datentyp: ""
  };
  valid = false;
  error = false;

  async onDownload(format: string) {
    const params = new URLSearchParams();
    this.where.forEach(w => params.append("w", w));
    window.open(
      process.env.VUE_APP_BASE_URL +
        "/export/" +
        this.name +
        "." +
        format +
        "?" +
        params.toString()
    );
  }
}
