
import Vue from "vue";
// used masonry https://github.com/paulcollett/vue-masonry-css

export default Vue.extend({
  name: "Section",
  props: {
    title: {
      type: String
    },
    dataMap: {
      type: Map,
      required: true
    },
    cols: {
      type: Number,
      default: 12
    }
  }
});
