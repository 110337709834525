
import Vue from "vue";
export default Vue.extend({
  name: "SingleSelect",
  props: {
    data: {
      type: Array,
      required: true
    },
    value: {
      type: Number,
      required: true
    },
    title: String,
    /** item-text like: https://vuetifyjs.com/en/api/v-select/#item-text */
    text: {
      type: [String, Function],
      required: true
    },
    /** item-text like: https://vuetifyjs.com/en/api/v-select/#item-value */
    element: {
      type: [String, Function],
      required: true
    },
    placeholder: String
  },
  data: function() {
    return { selected: this.value };
  },
  watch: {
    selected() {
      this.$emit("input", this.selected);
    }
  },
  mounted() {
    const _this = this as any;
    if (!_this.selected) {
      const select = _this.data[0][_this.element];
      // parse to disonect memory location
      this.selected = JSON.parse(JSON.stringify(select));
    }
  }
});
