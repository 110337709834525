
import { Vue, Component, Prop } from "vue-property-decorator";

export interface Copyright {
  year: number;
  copyText: string;
}

@Component({
  name: "Footer"
})
export default class Footer extends Vue {
  @Prop({ required: true })
  display!: boolean;

  @Prop()
  copyrights: Array<Copyright> | undefined;

  copyrightIconFA = "mdi-copyright";
}
