
import Vue from "vue";
import DateRangeSlider from "./DateRangeSlider.vue";

export interface DataObject {
  list: {
    DATUM: string;
    ANA_DATUM: string;
    // extend with more values
    [x: string]: any;
  }[];
  // extend with more values
  [x: string]: any;
}

export default Vue.extend({
  name: "RangeSelect",
  components: { DateRangeSlider },
  props: {
    title: String,
    /** v-model value */
    value: {
      type: Object,
      required: true
    },
    /** data array to select in */
    data: {
      type: Object as () => DataObject,
      required: true
    }
  },
  data: function() {
    return {
      dateRange: ["", ""],
      dates: [] as any
    };
  },
  computed: {
    reverseDates: function(): any[] {
      return ((this as any).dates || []).reverse();
    }
  },
  watch: {
    data: function() {
      this.filterDates();
    },
    dateRange: function() {
      this.filtereData();
    }
  },
  mounted() {
    this.filterDates();
  },
  methods: {
    filterDates: function() {
      if (!this.data.list) return [];
      const dates = this.data.list.map(item => ({
        text: item.DATUM || item.ana_datum,
        value: Date.parse(item.ANA_DATUM || item.ana_datum)
      }));
      if (dates?.length > 0) {
        // this.startDate = dates[0].value as any;
        // this.endDate = dates[dates.length - 1].value as any;
      }
      this.dates = dates;
    },
    filtereData: function(): any {
      if (!this.data) return [];
      const data = Object.assign({}, this.data);
      const startDate = Date.parse((this as any).dateRange[0]);
      const endDate = Date.parse((this as any).dateRange[1]);
      // fitler data by selected dates
      data.list = data.list.filter(
        obj =>
          startDate <= Date.parse(obj.ANA_DATUM || obj.ana_datum) &&
          endDate >= Date.parse(obj.ANA_DATUM || obj.ana_datum)
      );
      this.$emit("input", data);
    }
  }
});
