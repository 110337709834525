
import { Vue, Component, Prop } from "vue-property-decorator";
import Carousel from "@/components/Carousel.vue";

import axios from "axios";

export interface ResourceMeta {
  application: string;
  resource?: string;
  id: string;
  images?: Array<string>;
}

@Component({
  name: "Gallery",
  components: {
    Carousel
  }
})
export default class Gallery extends Vue {
  @Prop() galleryState!: boolean;
  @Prop() resourceMeta!: ResourceMeta;
  @Prop({ default: 1 }) startingImage!: number;
  @Prop() title!: string;

  images: Array<string> | null = null;

  galleryDisplayed = false;

  imgError = false;

  beforeMount() {
    this.collectImages();
  }

  collectImages() {
    if (this.resourceMeta.images !== undefined) {
      this.images = this.resourceMeta.images;
    } else {
      axios.post("/images", this.resourceMeta).then(response => {
        this.images = response.data;
      });
    }
  }

  onImgError() {
    this.imgError = true;
  }

  getImagesInOrder(x: Array<string>, num: number) {
    const tempArray = Array<string>();
    x.forEach(element => {
      if (element.charAt(element.length - 5) == String(num)) {
        tempArray.push(element);
      }
    });
    x.forEach(element => {
      if (element.charAt(element.length - 5) != String(num)) {
        tempArray.push(element);
      }
    });

    return tempArray;
  }

  updateState() {
    this.$emit("update-gallery-state", this.galleryState);
  }
}
