
import Vue from "vue";
import Diagram, { DiagramData } from "@/components/Diagram.vue";
import Table, { Column } from "@/components/Table.vue";

export default Vue.extend({
  name: "DiagramTableSwitch",
  components: {
    Diagram,
    Table
  },
  props: {
    /** Data object */
    data: {
      type: Object as () => DiagramData,
      required: true
    },
    /** Table columns */
    columns: {
      type: Array as () => Column[],
      required: true
    },
    /** Advice under diagram */
    advice: String,
    /** Config for diagram */
    xaxisformatter: {
      type: Function,
      default: null
    },
    /** Config for diagram */
    yaxisformatter: {
      type: Function,
      default: null
    },
    /** Config for diagram */
    restUrl: String
  },
  data: function() {
    return {
      tab: null
    };
  },
  watch: {
    data() {
      if (this.data?.list?.length == 1) (this as any).tab = "table";
    }
  }
});
