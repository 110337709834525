import Vue from "vue";

/* Filter for replace empty data values */
Vue.filter("emptyVal", (value: string | null | undefined) => {
  if (value == "" || value == null) {
    return "-";
  } else if (typeof value == "number") {
    return (value as number).toString().replace(".", ",");
  } else {
    return value;
  }
});

/* Filter for replace empty data values */
Vue.filter("binaryTrueFalse", (value: number) => {
  switch (value) {
    case 1:
      return "ja";
    case 0:
      return "nein";
    default:
      "-";
  }
});

Vue.filter("dateTransform", (value: string) => {
  const date = new Date(value);
  if (value != null) {
    const nDay = date.getDate();
    const day = nDay < 10 ? "0" + nDay : nDay;
    const nMonth = date.getMonth() + 1;
    const month = nMonth < 10 ? "0" + nMonth : nMonth;

    return `${day}.${month}.${date.getFullYear()}`;
  }
  return "-";
});

Vue.filter("formatFileLink", (value: string, replace: string) => {
  const filename = replace.replace("/", "_");
  return value.replace("{baseurl}", filename);
});

Vue.filter("createLink", (value: string, url: string, fileName: string) => {
  if (value === null) {
    return fileName;
  }
  const filename = fileName.replace("/", "_");
  const baseUrl = url + filename;
  return value.replace("{baseurl}", baseUrl);
});

Vue.filter("filterYearFromDate", (value: string, adjust: number) => {
  if (/[0-9][0-9].[0-9][0-9].[0-9][0-9][0-9][0-9]/.test(value)) {
    // German date format (unsupported by Firefox in Date constructor)
    return value.split(".")[2];
  }
  const date = new Date(value);
  return date.getFullYear() + adjust;
});

Vue.filter("toFractionDigits", (value: string | null, fraction: number) => {
  if (value === null) {
    return "-";
  }
  return parseFloat(value)
    .toFixed(fraction)
    .replace(".", ",");
});

Vue.filter("toLowerCase", (value: string) => {
  return value.toLowerCase();
});
