import * as d3 from "d3";
import $ from "jquery";

/**
 * Util class for diagrams
 */
export const utils = {
  germanFormat: function(number) {
    const stringReverse = function(str) {
      return str
        .split("")
        .reverse()
        .join("");
    };
    const _ref = number.toFixed(2).split(".");
    let preComma = _ref[0];
    const postComma = _ref[1];
    preComma = stringReverse(
      stringReverse(preComma)
        .match(/.{1,3}/g)
        .join(".")
    );
    return "" + preComma + "," + postComma;
  }
};

/**
 * Draw a line diagram
 */
const LineDiagram = function(idContainer) {
  // CONSTANTS
  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  this.TOP = 10;
  this.RIGHT = 20;
  this.BOTTOM = 103;
  this.LEFT = 70;
  this.OFFSET_V = 20;
  this.OFFSET_H = 48;
  this.LEGENDLINEHEIGHT = 12;
  this.DEFAULTLEGENDBOXHEIGHT = 20;
  // ATTRIBUTES
  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  this.idContainer = idContainer;
  this.$svgContainer = null;
  this.svgDiagram = null;
  this.diagramArea = null;
  this.width = null;
  this.height = null;
  this.svgGroup = null;
  this.formatDate = d3.time.format("%d.%m.%Y");
  this.selData = [];
  this.xValues = [];
  this.$tooltip = null;
  this.bisectDate = d3.bisector(d3.ascending);
  this.bisectDescending = d3.bisector(d3.descending);
  this.bisectAscending = d3.bisector(d3.ascending);
  this.circle = null;
  this.dashLine = null;
  this.svgLineX = null;
  this.svgLineY = null;
  this.minYValue = null;
  this.maxYValue = null;
  this.minXValue = null;
  this.maxXValue = null;
  this.isAxisYFrom0 = false;

  this.mobile = false;

  this.margin = null;
  this.restUrl = "";
};
// METHODS
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
LineDiagram.prototype.init = function(data) {
  this.margin = {
    top: this.TOP,
    right: this.RIGHT,
    bottom: this.BOTTOM,
    left: this.LEFT
  };

  //wegen Redesing Wasser/Abfluss hier Weiche, weil this.$svgContainer.width()/this.$svgContainer.height() im neuen css nicht gestezt weil dafÃ¼r
  //Atribute preserveAspectRatio und view
  if (this.restUrl != null && this.restUrl.indexOf("wasserstand") > 0) {
    this.createSvg_Redesign(data);
  } else {
    this.createSvg(data);
  }
  this.addTooltip();
};

LineDiagram.prototype.createSvg_Redesign = function(data) {
  this.$svgContainer = $("#" + this.idContainer);
  //console.log(this.$svgContainer.width());
  this.width = 690 - this.margin.left - this.margin.right;
  // set rect hight for only data line legend
  this.height = 365 - this.margin.top - this.margin.bottom;

  this.mobile = false;

  let legendBoxHeight = this.DEFAULTLEGENDBOXHEIGHT;
  if (data.limits !== undefined) {
    // set rect hight for all limit lines legends
    legendBoxHeight += this.LEGENDLINEHEIGHT * data.limits.length;
  }

  // Adds the svg canvas
  const svg_width = 690; //this.width + this.margin.left + this.margin.right;

  //fÃ¼r Redesign wasserstand_new wieder 365 benutzen wegen Tooltips!!!
  const svg_heigth =
    this.height + legendBoxHeight + this.margin.top + this.margin.bottom;

  const _svg = d3
    .select("#" + this.idContainer)
    .append("svg")
    //.attr('width', this.width + this.margin.left + this.margin.right)
    //.attr('height', this.height+legendBoxHeight + this.margin.top + this.margin.bottom)
    .attr("preserveAspectRatio", "xMinYMin meet")
    .attr("viewBox", "0 0 " + svg_width + " " + svg_heigth + " ");
  //.classed('svg-container', true);
  this.svgDiagram = _svg
    .append("g")
    .attr(
      "transform",
      "translate(" + this.margin.left + "," + this.margin.top + ")"
    );

  // Defines the div for the tooltip
  this.diagramArea = this.svgDiagram
    .append("rect")
    .attr("class", "diagram-area")
    .attr("height", this.height)
    .attr("width", this.width + 10); //+10 to catch tooltip event at the right border!

  this.$svgContainer.append(this.$tooltip);

  this.svgGroup = this.svgDiagram.append("g");
};

LineDiagram.prototype.createSvg = function(data) {
  this.$svgContainer = $("#" + this.idContainer);

  this.width =
    this.$svgContainer.width() - this.margin.left - this.margin.right;
  // set rect hight for only data line legend
  this.height = 355 - this.margin.top - this.margin.bottom;

  this.mobile = this.width < 500;

  let legendBoxHeight = this.DEFAULTLEGENDBOXHEIGHT;
  if (data.limits !== undefined) {
    // set rect hight for all limit lines legends
    legendBoxHeight += this.LEGENDLINEHEIGHT * data.limits.length;
  }
  const totalHeight =
    this.height + legendBoxHeight + this.margin.top + this.margin.bottom;

  // Adds the svg canvas
  const _svg = d3
    .select("#" + this.idContainer)
    .append("svg")
    .attr("width", this.width + this.margin.left + this.margin.right)
    .attr("height", totalHeight);
  this.svgDiagram = _svg
    .append("g")
    .attr(
      "transform",
      "translate(" + this.margin.left + "," + this.margin.top + ")"
    );

  // Defines the div for the tooltip
  this.diagramArea = this.svgDiagram
    .append("rect")
    .attr("class", "diagram-area")
    .attr("height", this.height)
    .attr("width", this.width + 8); //+10 to catch tooltip event at the right border!

  this.$svgContainer.append(this.$tooltip);

  this.svgGroup = this.svgDiagram.append("g");
};

LineDiagram.prototype.addTooltip = function() {
  this.$tooltip = $("<div>").addClass("tooltip");
  this.$svgContainer.append(this.$tooltip);
  this.$tooltip.hide();
  this.addTooltipEvents();
};

function createLimitData(x0, x1, y) {
  const limitData = [];
  const d1 = new Object();
  d1.xValue = x0;
  d1.yValue = y;
  limitData.push(d1);
  const d2 = new Object();
  d2.xValue = x1;
  d2.yValue = y;
  limitData.push(d2);
  return limitData;
}

LineDiagram.prototype.createDiagram = function(data) {
  let valueline;
  const _this = this;
  // initialization
  this.svgGroup.selectAll("*").remove();

  this.xValues = [];
  this.selData = [];
  this.prepareData(data);
  if (data.limits !== undefined) {
    let index = 0;
    data.limits.forEach(function(d) {
      if (Array.isArray(d.limit)) {
        for (const limit of d.limit) {
          _this.showLimitLine(
            createLimitData(
              Math.max(limit.x0, _this.minXValue),
              Math.min(limit.x1, _this.maxXValue),
              limit.y
            ),
            "line limit" + index
          );
        }
      } else {
        _this.showLimitLine(
          createLimitData(_this.minXValue, _this.maxXValue, d.limit),
          "line limit" + index
        ); // set the line color
      }
      index++;
    });
  }

  if (this.selData.length > 0) {
    // Add the valueline path to draw the line
    if (this.restUrl != null && this.restUrl.indexOf("gus") > 0) {
      //alert(_this.svgLineX(d.xValue));
      // Add the scatterplot

      this.svgGroup
        .selectAll("dot")
        .data(this.selData)
        .enter()
        .append("circle")
        .style("fill", "black")
        .style("stroke", "darkblue")
        .style("opacity", 1)
        .attr("r", 3.5)
        .attr("cx", function(d) {
          return _this.svgLineX(d.xValue);
        })
        .attr("cy", function(d) {
          return _this.svgLineY(d.yValue);
        });
    } else if (
      this.restUrl != null &&
      this.restUrl.indexOf("grundwasser") > 0
    ) {
      // phwert: Ordinate bei 4-10
      if (
        (this.restUrl.indexOf("stoff_nr") > 0 ||
          this.restUrl.indexOf("stoffNr") > 0) &&
        this.restUrl.indexOf("10160") > 0
      ) {
        this.svgLineY = d3.scale
          .linear()
          .domain([4, 10])
          .range([this.height, 0]);
      }
      // Nitrat: falls max < 50 = Ordinate auf 50 setzen
      else if (
        (this.restUrl.indexOf("stoff_nr") > 0 ||
          this.restUrl.indexOf("stoffNr") > 0) &&
        this.restUrl.indexOf("12070") > 0 &&
        this.maxYValue < 50
      ) {
        this.svgLineY = d3.scale
          .linear()
          .domain([0, 50])
          .range([this.height, 0]);
      }
      //alle ausser ph-wert: Ordinate bei 0 anfangen
      else {
        this.svgLineY = d3.scale
          .linear()
          .domain([0, this.maxYValue])
          .range([this.height, 0]);
      }

      valueline = d3.svg
        .line()
        .defined(function(d) {
          return !isNaN(d.yValue);
        })
        .x(function(d) {
          return _this.svgLineX(d.xValue);
        })
        .y(function(d) {
          return _this.svgLineY(d.yValue);
        });

      //Mehr als 3 Messwerte
      if (this.selData.length > 3) {
        this.svgGroup
          .append("path")
          .attr("class", "line")
          .attr("d", valueline(this.selData));
      } else {
        this.svgGroup
          .selectAll("dot")
          .data(this.selData)
          .enter()
          .append("circle")
          .style("fill", "black")
          .style("stroke", "darkblue")
          .style("opacity", 1)
          .attr("r", 3.5)
          .attr("cx", function(d) {
            return _this.svgLineX(d.xValue);
          })
          .attr("cy", function(d) {
            return _this.svgLineY(d.yValue);
          });
      }
    } else if (
      this.restUrl != null &&
      this.restUrl.indexOf("grundwasser") < 0
    ) {
      //valueline ausblenden, wenn Y keine Zahl ist -> .defined(d => !isNaN(d.yValue))
      valueline = d3.svg
        .line()
        .defined(function(d) {
          return !isNaN(d.yValue);
        })
        .x(function(d) {
          return _this.svgLineX(d.xValue);
        })
        .y(function(d) {
          return _this.svgLineY(d.yValue);
        });

      this.svgGroup
        .append("path")
        .attr("class", "line")
        .attr("d", valueline(this.selData));
    }

    this.addAxis();
    this.addAxisLabels(data);
    this.addLegend(data);
  }
};

LineDiagram.prototype.showLimitLine = function(limitData, cssClass) {
  const _this = this;

  // filter entries with undefined values to avoid exceptions later
  limitData = limitData.filter(
    d => typeof d.xValue != "undefined" && typeof d.yValue != "undefined"
  );

  // Add the valueline path to draw the line
  const valueline = d3.svg
    .line()
    .x(function(d) {
      return _this.svgLineX(d.xValue);
    })
    .y(function(d) {
      return _this.svgLineY(d.yValue);
    });
  this.svgGroup
    .append("path")
    .attr("class", cssClass)
    .attr("d", valueline(limitData));
};

LineDiagram.prototype.formatXAxisValue = function(xValue) {
  return xValue;
};

LineDiagram.prototype.formatYAxisValue = function(yValue) {
  return yValue;
};

LineDiagram.prototype.formatXAxisValueForTooltip = function(xValue) {
  return this.formatXAxisValue(xValue);
};

LineDiagram.prototype.prepareData = function(data) {
  const __this = this;

  data.list.forEach(function(d) {
    if (d.yValue !== undefined) {
      __this.selData.push(d);
      __this.xValues.push(d.xValue);
    }
  });

  if (this.selData.length > 0) {
    this.minXValue = d3.min(this.selData, function(d) {
      return d.xValue;
    });
    this.maxXValue = d3.max(this.selData, function(d) {
      return d.xValue;
    });

    // Sets the ranges
    this.svgLineX = d3.scale
      .linear()
      .domain([this.minXValue, this.maxXValue])
      .range([0, this.width]);

    // Set default Param 'isAxisYFrom0' to true if set in dataset
    if (data.setYaxisfrom0) {
      this.isAxisYFrom0 = true;
    }

    if (this.isAxisYFrom0 === true) {
      this.minYValue = 0;
    } else {
      this.minYValue = d3.min(this.selData, function(d) {
        return d.yValue;
      });
    }
    this.maxYValue = d3.max(this.selData, function(d) {
      return d.yValue;
    });

    let maxLimit = null;
    if (data.limits !== undefined) {
      // get max limit
      data.limits.forEach(function(d) {
        const ly = Array.isArray(d.limit)
          ? Math.max(...d.limit.map(e => e.y))
          : d.limit;
        if (maxLimit === null || maxLimit < ly) {
          maxLimit = ly;
        }
      });
      this.maxYValue = d3.max(this.selData, function(d) {
        return maxLimit > d.yValue ? maxLimit + maxLimit / 10 : d.yValue;
      });
    }

    this.minYValue = d3.min([this.minYValue, data.yValue - 0.5]);
    this.maxYValue = d3.max([this.maxYValue, data.yValue + 0.5]);

    // Set Y-Axis Limit to custom fixed value if set in diagram data set
    this.minYValue = !isNaN(data.yLimit?.min)
      ? data.yLimit.min
      : this.minYValue;

    this.maxYValue = !isNaN(data.yLimit?.max)
      ? data.yLimit.max
      : this.maxYValue;

    this.svgLineY = d3.scale
      .linear()
      .domain([this.minYValue, this.maxYValue])
      .range([this.height, 0]);
  }
};

/**
 * Fix error in IE for x-Axis values
 * http://stackoverflow.com/questions/21298338/css-transform-on-svg-elements-ie9
 */
/*
LineDiagram.prototype.fixErrorInIE = function() {
  const _fix = document.querySelectorAll(".axis.x text");
  for (let index = 0; index < _fix.length; ++index) {
    const transform = getComputedStyle(_fix[index]).getPropertyValue(
      "transform"
    );
    _fix[index].setAttribute("transform", transform);
  }
};
*/

LineDiagram.prototype.addAxis = function() {
  const _this = this;

  const _xAxis = d3.svg
    .axis()
    .scale(_this.svgLineX)
    .orient("bottom");
  const _delta = this.maxXValue - this.minXValue;

  //_xAxis.ticks(d3.time.month, 1);
  _xAxis.ticks(20);
  _xAxis.tickPadding([-10]);
  _xAxis.tickFormat(function(d) {
    return _this.formatXAxisValue(d);
  });

  const _yAxis = d3.svg
    .axis()
    .scale(this.svgLineY)
    .orient("left")
    .ticks(10);
  _yAxis
    .innerTickSize(-this.width)
    .outerTickSize(0)
    .tickPadding(10);
  _yAxis.tickFormat(function(d) {
    return _this.formatYAxisValue(d);
  });
  // Add the X Axis
  this.svgGroup
    .append("g")
    .attr("class", _delta <= 3 ? "x axis small" : "x axis")
    .attr("transform", "translate(0," + this.height + ")")
    .call(_xAxis)
    .style("font-size", "12px");

  // Add the Y Axis
  this.svgGroup
    .append("g")
    .attr("class", "y axis")
    .call(_yAxis)
    .style("font-size", "12px");

  //  this.fixErrorInIE();
};

LineDiagram.prototype.addAxisLabels = function(data) {
  this.svgGroup
    .append("text")
    .attr("x", -150)
    .attr("y", -40)
    .attr("class", "lblYaxis")
    .attr("transform", "matrix(0, -1, 1, 0, 0, 0)")
    .html(data.yUnit)
    .style("font-size", "12px");
  this.svgGroup
    .append("text")
    .attr("x", this.mobile ? 20 : this.width / 3)
    .attr("y", this.height + this.BOTTOM - 14)
    .attr("class", "lblXaxis")
    .text(unescape(data.xUnit))
    .style("font-size", "12px");
};

LineDiagram.prototype.addLegend = function(data) {
  const _this = this;

  const startY = 14;

  const legendWidth = this.mobile ? 200 : 310;

  const _g = this.svgGroup
    .append("g")
    .attr("class", "legend")
    .attr(
      "transform",
      "translate(" +
        (this.width - legendWidth) +
        // (this.width / 2 - 25) +
        "," +
        (this.height + this.BOTTOM - 20) +
        ")"
    );

  if (this.restUrl != null && this.restUrl.indexOf("gus") < 0) {
    // exists limit lines?
    if (data.limits === undefined) {
      // set rect hight for only data line legend
      _g.append("rect")
        .attr("height", _this.DEFAULTLEGENDBOXHEIGHT)
        .attr("width", legendWidth);
    } else {
      // set rect hight for all limit lines legends
      _g.append("rect")
        .attr(
          "height",
          _this.DEFAULTLEGENDBOXHEIGHT +
            _this.LEGENDLINEHEIGHT * data.limits.length
        )
        .attr("width", legendWidth);
    }

    // draw legend for data line
    _g.append("path").attr("d", "M12 9 l20 0 Z");
    _g.append("text")
      .attr("x", 40)
      .attr("y", startY)
      .text(unescape(data.legendText))
      .style("font-size", "12px");
  }

  if (data.limits !== undefined) {
    // draw legend for limit lines
    let index = 0;
    data.limits.forEach(function(d) {
      const yPos = 9 + 12 * (index + 1);
      _g.append("path")
        .attr("d", "M12 " + yPos + " l20 0 Z")
        .attr("class", "line limit" + index);
      _g.append("text")
        .attr("x", 40)
        .attr("y", startY + _this.LEGENDLINEHEIGHT * (1 + index))
        .text(unescape(d.legendText))
        .style("font-size", "12px");

      index++;
    });
  }
};

LineDiagram.prototype.mouseOverHandler = function(x, y, val) {
  this.$tooltip
    .html(val)
    .css("left", x - this.OFFSET_H + "px")
    .css("top", y - this.OFFSET_V + "px");
};

LineDiagram.prototype.addTooltipEvents = function() {
  const _this = this;
  this.circle = this.svgDiagram
    .append("circle")
    .attr("class", "y")
    .attr("r", 4);
  this.dashLine = this.svgDiagram
    .append("line")
    .attr("class", "dash-line")
    .style("opacity", 0)
    .attr("y1", 0)
    .attr("y2", this.height);

  this.diagramArea.on("mouseout", function() {
    _this.$tooltip.hide();
    _this.circle.style("opacity", 0);
    _this.dashLine.style("opacity", 0);
  });
  this.diagramArea.on("mousemove", function() {
    const _mousePos = d3.mouse(this);
    //console.log(this);

    const _x = _mousePos[0] - 3;

    if (_x > 0) {
      if (!_this.$tooltip.is(":visible")) {
        _this.$tooltip.show();
        _this.circle.style("opacity", 1);
        _this.dashLine.style("opacity", 0.5);
      }
      const _y = _mousePos[1];
      const _x0 = _this.svgLineX.invert(_x);

      //      const _index = _this.bisectDescending.right(_this.xValues, _x0);
      const _index = _this.bisectAscending.left(_this.xValues, _x0);
      const _sel = _this.selData[_index - 1];
      if (_sel) {
        _this.$tooltip.show();
        const _x2 = _this.svgLineX(_sel.xValue);
        //console.log(_sel.yValue +" " + _sel.xValue);
        //Tooltip ausblenden, wenn Y keine Zahl ist
        if (!isNaN(_sel.yValue)) {
          _this.mouseOverHandler(
            _x,
            _y,
            _this.formatXAxisValueForTooltip(_sel.xValue) +
              "<br/>" +
              _this.formatYAxisValue(_sel.yValue)
          );
          _this.circle
            .style("opacity", 1)
            .attr("cx", _x2)
            .attr("cy", _this.svgLineY(_sel.yValue));
          _this.dashLine
            .style("opacity", 1)
            .attr("x1", _x2)
            .attr("x2", _x2);
        }
      }
    }
  });
};

export default LineDiagram;
