import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

function hasQueryParams(route) {
  return !!Object.keys(route.query).length;
}

/**
 * Source from : https://stackoverflow.com/questions/45091380/vue-router-keep-query-parameter-and-use-same-view-for-children
 */
export function beforeEach(to, from, next) {
  // If the new route (to) does not have its own parameters, then they will be taken from the previous route (from)
  if (!hasQueryParams(to) && hasQueryParams(from)) {
    next({ name: to.name, query: from.query });
  } else {
    next();
  }
}

export function createRouter(base, routes) {
  const router = new VueRouter({
    mode: "history",
    base,
    routes
  });

  router.beforeEach(beforeEach);
  return router;
}
