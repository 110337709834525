
import { formatDate } from "@/helpers/formatters";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";

@Component({ components: {} })
export default class DateRangeSlider extends Vue {
  @Prop({ required: true })
  value!: string[];
  @Prop({ required: true })
  dates!: (string | Date)[];
  @Prop({ default: false })
  disabled!: boolean;

  rangeIndices: number[] = [-1, -1];

  get from() {
    return this.dates[this.rangeIndices[0]];
  }

  get to() {
    return this.dates[Math.min(this.dates.length - 1, this.rangeIndices[1])];
  }

  formatDate(date: string) {
    return date ? formatDate(date) : "";
  }

  mounted() {
    this.fullRange();
  }

  fullRange() {
    this.rangeIndices =
      this.dates && this.dates.length ? [0, this.dates.length - 1] : [-1, -1];
  }

  @Watch("dates")
  onDatesChanged(dates: string[], previous: string[]) {
    if (dates.length != previous.length) {
      this.fullRange();
    }
    this.emit();
  }

  @Watch("rangeIndices")
  onRangeChanged() {
    this.emit();
  }

  emit() {
    const value = [this.from, this.to];
    if (this.value.toString() == value.toString()) {
      return;
    }
    this.$emit("input", value);
  }
}
