
import Vue from "vue";
import "@/helpers/filters";
import ErrorDialog from "@/components/ErrorDialog.vue";

/** deprecated */
export interface TableMeta {
  name?: string;
  height: number | string;
  baseUrl?: string;
  columns: {
    col: string;
    key: string;
    link?: string;
    date?: boolean;
  }[];
}

export interface Column {
  text: string;
  key?: string;
  custom?: Function;
  date?: boolean;
  link?: string;
  /** alternative to text */
  col?: string;
  linkToCustomRouterLink?: boolean;
}

export default Vue.extend({
  name: "Table",
  components: {
    ErrorDialog
  },
  props: {
    /** deprecated property */
    tableMeta: Object as () => TableMeta,
    /** deprecated property */
    tableData: Array as () => any[],
    /** new property */
    data: Array as () => any[],
    /** new property */
    columns: Array as () => Column[],
    /** new property */
    title: String,
    /** new property */
    height: String,
    /** new property */
    baseUrl: String,
    /** function to be called on click */
    customRouterLink: {
      type: Function,
      required: false,
      default: null
    }
  },
  data() {
    return {
      /** unused data */
      size: {
        full: "450px",
        twoThird: "300px",
        oneThird: "200px"
      }
    };
  },
  computed: {
    tableColumns: function() {
      if (this.columns) return this.columns;
      if ((this.tableMeta as any)?.columns)
        return (this.tableMeta as any).columns;
      return [];
    },
    tData: function() {
      return this.data || this.tableData || [];
    },
    theight: function() {
      if (this.height) return this.height;
      else if ((this as any).tableMeta?.height)
        return (this as any).size[(this as any).tableMeta?.height];
      else return undefined;
    }
  }
});
