import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import de from "vuetify/src/locale/de";
import "@mdi/font/css/materialdesignicons.css";

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { de },
    current: "de"
  },
  theme: {
    themes: {
      light: {
        primary: "#871D33",
        secondary: "#8E8E8E",
        accent: "#FF0000",
        error: "#FF5252",
        info: "#FB7AE2",
        success: "#4CAF50",
        warning: "#FFC107"
      }
    }
  }
});
