
import Vue from "vue";
import Axios from "axios";
import { keysToUpperCase } from "./Loader.vue";

export interface View {
  name: string;
  condition?: string | string[];
}

export default Vue.extend({
  name: "Loader",
  props: {
    /*
     * Loader types:
     * https://vuetifyjs.com/en/api/v-skeleton-loader/#type
     */
    loader: {
      type: String,
      required: true
    },
    /** multiple view objects */
    views: {
      type: [Object as () => View, Array as () => View[]],
      required: true
    },
    /** remove one array layer - return all calls results in one array */
    flat: {
      type: Boolean,
      default: false
    },
    /** if true proceed even data is empty - no empty message will be displayed */
    skipEmpty: {
      type: Boolean,
      default: false
    },
    /** define a message when response is empty */
    emptyMessage: {
      type: String,
      default: "Keine Messwerte vorhanden."
    },
    /** define a message when request failed */
    errorMessage: {
      type: String,
      default: "Es ist ein Fehler bei der Datenabfrage aufgetreten..."
    },
    lowerCaseFieldNames: {
      type: Boolean,
      default: false
    }
  },
  data: () => {
    return {
      loading: true,
      baseUrl: "/data",
      delay: 500,
      data: [] as any[],
      error: false
    };
  },
  mounted() {
    this.loadData();
  },
  watch: {
    views: function(newview, oldview) {
      // only load new Data if views really differce
      const force = JSON.stringify(newview) != JSON.stringify(oldview);
      console.log("Force Loader Update: " + force);
      if (force) this.loadData();
    }
  },
  computed: {
    empty: function() {
      const hasType = typeof this.data !== "undefined";
      const isEmptyObject =
        this.data.constructor === Object && Object.keys(this.data).length === 0;
      const hasLength = Array.isArray(this.data) ? this.data.length > 0 : true;

      const hasSubLength =
        !this.flat && Array.isArray(this.data) && this.data.length > 0
          ? this.data
              .map(subData =>
                Array.isArray(subData) ? subData.length > 0 : true
              )
              .includes(true)
          : true;
      return (
        !this.skipEmpty &&
        (!hasType || isEmptyObject || !hasLength || !hasSubLength)
      );
    }
  },
  methods: {
    loadData() {
      // reset error
      this.error = false;
      // check if views is object
      if (Object.keys(this.views).length === 0) return;
      // Resolve Promises and merge them to one object
      this.load(Array.isArray(this.views) ? this.views : [this.views])
        .then(result => {
          // set data in next tick, that loaded event can be handeled first
          this.$nextTick(() => {
            this.error = false;
            this.data = result;
          });
          this.$emit("loaded", result);
        })
        .catch(err => {
          console.error(err);
          // set error in next tick, that error event can be handeled first
          this.$nextTick(() => {
            this.data = [];
            this.error = true;
          });
          this.$emit("error", err);
        });
    },
    startLoading(): number {
      // change loading state with delay

      // remove loading toogle delay because of confusing error
      // return setTimeout(() => {
      //   if (this.loading == false) this.loading = true;
      // }, this.delay);

      this.loading = true;
      return 0;
    },
    finishLoading() {
      this.loading = false;
    },
    load(views: View[]): Promise<any> {
      // enable skeleton loader
      this.startLoading();
      // do request for each view
      const loadRequests = views.map(view => {
        // create request params
        const params = new URLSearchParams();
        const url = this.baseUrl + "/" + view.name;
        if (view.condition) {
          const where =
            typeof view.condition == "string"
              ? [view.condition]
              : view.condition;
          where.filter(w => w).forEach(w => params.append("w", w));
        }
        // do request
        return Axios.get(url, { params, responseType: "json" }).then(
          this.lowerCaseFieldNames
            ? response => response.data
            : response => keysToUpperCase(response.data)
        );
      });

      // Resolve Promises and merge them to one object
      return Promise.all(loadRequests)
        .then(r => {
          // disable skeleten loader
          this.finishLoading();

          if (this.flat)
            // map result objects to one object
            return r.flat();
          else return r;
        })
        .catch(err => {
          // disable skeleten loader
          this.finishLoading();
          throw err;
        });
    }
  }
});
