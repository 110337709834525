
import { Vue, Component, Prop } from "vue-property-decorator";
import Navigation from "@/components/Navigation.vue";
import Footer, { Copyright } from "@/components/Footer.vue";

@Component({ components: { Navigation, Footer } })
export default class Page extends Vue {
  @Prop({ required: true })
  nav!: any;

  @Prop({ required: true })
  footer!: boolean;

  @Prop()
  copyrights: Array<Copyright> | undefined;

  get showHeader() {
    return !this.$route.meta?.hideTabs;
  }
}
