import LineDiagram from "@/assets/diagrams/m_drawGenericDiagram";
import * as d3 from "d3";

export let mCustomLineDiagram = null;

/**
 * Prepare custom data and show diagram.
 */
export function createDiagramPrototype(
  restURL,
  xaxisformatter,
  yaxisformatter,
  yaxisformatterTooltip
) {
  // create custom diagram from DRAW_GENERIC_DIAGRAM and customization/overwrite of the methods
  const CustomLineDiagram = function(idContainer) {
    const mFormatDate = d3.time.format("%d.%m.%Y");

    LineDiagram.call(this, idContainer); // call super constructor.

    // OVERWRITE THE METHODS ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    this.createDiagram = function(data) {
      this.restUrl = restURL;
      //call method from the super class
      LineDiagram.prototype.createDiagram.call(this, data);
    };

    /**
     * format x axis values for rendering
     */
    this.formatXAxisValue = function(xValue) {
      if (xaxisformatter instanceof Function) {
        return xaxisformatter(xValue, mFormatDate);
      } else {
        return mFormatDate(new Date(xValue));
      }
    };

    /**
     * format y axis values for rendering
     */
    this.formatYAxisValue = function(yValue) {
      if (yaxisformatter instanceof Function) {
        return yaxisformatter(yValue, mFormatDate);
      } else {
        return yValue;
      }
    };

    if (yaxisformatterTooltip) {
      this.formatXAxisValueForTooltip = yaxisformatterTooltip;
    }
  };
  return CustomLineDiagram;
}

export function drawDiagram(CustomLineDiagram, idContainer, data) {
  // create diagram
  CustomLineDiagram.prototype = Object.create(LineDiagram.prototype);
  CustomLineDiagram.prototype.constructor = CustomLineDiagram;
  mCustomLineDiagram = new CustomLineDiagram(idContainer);
  mCustomLineDiagram.init(data);
  mCustomLineDiagram.createDiagram(data);
  return mCustomLineDiagram;
}

export function redrawDiagram(CustomLineDiagram, idContainer, data) {
  // create diagram
  CustomLineDiagram.prototype = Object.create(LineDiagram.prototype);
  CustomLineDiagram.prototype.constructor = CustomLineDiagram;
  if (mCustomLineDiagram === null) {
    mCustomLineDiagram = new CustomLineDiagram(idContainer);
    mCustomLineDiagram.init(data);
  }
  mCustomLineDiagram.createDiagram(data);
  return mCustomLineDiagram;
}
