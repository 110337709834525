
import Vue from "vue";
import {
  createDiagramPrototype,
  drawDiagram,
  redrawDiagram
} from "@/assets/diagrams/m_customDiagramGenerator";
import ErrorDialog from "@/components/ErrorDialog.vue";

export interface Limit {
  x0: Date;
  x1: Date;
  y: number;
}

/** Chart need this data structure: */
export interface DiagramData {
  legendText: string;
  limits?: {
    limit?: number | Limit[];
    legendText?: string;
  }[];
  list: {
    nNHoehe?: number;
    abstich?: string;
    zusatzBez?: string;
    parameterStatus?: string;
    yValue?: string | number;
    xValue: number;
  }[];
  parameter?: string;
  parameterBezeichnung?: string;
  quellen?: boolean;
  xUnit: string;
  yUnit: string;
  yLimit?: any;
}

export default Vue.extend({
  name: "Diagram",
  components: {
    ErrorDialog
  },
  props: {
    /** data object for the diagram in DiagramData structure */
    data: {
      type: Object as () => DiagramData,
      required: true
    },
    /** defines an xaxis formatter function */
    xaxisformatter: {
      type: Function,
      default: (xValue, dateFormatter) => dateFormatter(new Date(xValue))
    },
    xaxisformatterTooltip: {
      type: Function,
      required: false
    },
    /** defines an yaxis formatter function */
    yaxisformatter: {
      type: Function,
      default: (yValue, dateFormatter) => yValue
    },
    /** sets the url for custom code based on this parameter */
    restUrl: {
      type: String,
      default: ""
    }
  },
  data: function() {
    return {
      idContainer: "svg-container",
      isDrawn: false
    };
  },
  mounted() {
    this.drawDiagram();
  },
  created() {
    window.addEventListener("resize", this.onResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.onResize);
  },
  watch: {
    data: function() {
      // if (oldData?.list?.length) this.redrawDiagram();
      // else
      this.drawDiagram();
    },
    diagramPrototype: function() {
      //this.redrawDiagram();
      this.drawDiagram();
    }
  },
  computed: {
    diagramPrototype: function(): any {
      return createDiagramPrototype(
        this.restUrl,
        this.xaxisformatter,
        this.yaxisformatter,
        this.xaxisformatterTooltip
      );
    }
  },
  methods: {
    drawDiagram: function() {
      // use nextTick because the template has to be refreshed before.
      Vue.nextTick(() => {
        if (this.data.list?.length > 1) {
          if (!this.isDrawn) {
            this.isDrawn = true;
            drawDiagram(this.diagramPrototype, this.idContainer, this.data);
          } else
            redrawDiagram(this.diagramPrototype, this.idContainer, this.data);
        }
      });
    },
    redrawDiagram: function() {
      // use nextTick because the template has to be refreshed before.
      Vue.nextTick(() => {
        if (this.data.list?.length > 1) {
          redrawDiagram(this.diagramPrototype, this.idContainer, this.data);
        }
      });
    },
    onResize: function() {
      const svgContainer = document.getElementById(this.idContainer);
      if (svgContainer) {
        svgContainer.innerHTML = "";
      }
      this.isDrawn = false;
      this.drawDiagram();
    }
  }
});
